import React, { useEffect, userEffect } from "react";
import KONVEYColorful from "../assets/KONVEYColorful.png";
import Footer from "./Footer";
import Button from "../components/Button";
import Axios from "../components/Axios";
import { APP } from "../utils/constant";

const Verify = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    Axios.post(
      APP.API_MAIN_URL + "/account/verify",
      {
        verify_token: token,
      },
      config
    )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);
  return (
    <div className="h-screen w-full ">
      <div className="flex w-full h-[70%] items-center justify-center">
        <div className=" flex-col bg-white  md:w-1/2 p-8">
          <div>
            <img src={KONVEYColorful} alt="logo" width={100} height={50} />
          </div>

          <div className="flex flex-col items-center justify-center border p-4 mt-4 border-primary border-opacity-10 rounded shadow-sm">
            <div className="text-success flex items-center justify-center p-4">
              <p>Your account is verified successfully!</p>
            </div>

            <Button
              content="Login"
              type="button"
              btnColor="primary"
              style="flex items-center justify-center text-white w-50 h-[40px] mt-4"
              onClick={() => {
                window.location.replace("/");
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-full h-[30%] absolute">
        <Footer />
      </div>
    </div>
  );
};

export default Verify;
