import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FiEyeOff } from "react-icons/fi";
import { FaRegEye } from "react-icons/fa";
import { useForm } from "react-hook-form";
import Input from "../components/Input";
import StyledInput from "../components/StyledInput";
import Button from "../components/Button";
import Axios from "../components/Axios";
import { ToastComponent, ToasterComponent } from "../components/Toast";
import Loader from "../components/Loader";
import KONVEYColorful from "../assets/KONVEYColorful.png";
import { APP } from "../utils/constant";

const Login = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const userRole = localStorage.getItem("role");
  const [socialAuthFetching, setSocialAuthFetching] = useState(false);
  const [socialAuthProviders, setSocialAuthProviders] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  // function to login with google
  const handleGoogleLogin = async () => {
    try {
      const response = await Axios.get(APP.API_MAIN_URL + "/auth/google")
        .then((res) => {
          if (res.status === 200) {
            setRedirectUrl(res.data.redirect_url);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // function to login with facebook
  const handleFacebookLogin = async () => {
    try {
      const response = await Axios.get(APP.API_MAIN_URL + "/auth/facebook")
        .then((res) => {
          if (res.status === 200) {
            setRedirectUrl(res.data.redirect_url);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // function to login with twitter
  const handleTwitterLogin = async () => {
    try {
      const response = await Axios.get(APP.API_MAIN_URL + "/auth/twitter")
        .then((res) => {
          if (res.status === 200) {
            setRedirectUrl(res.data.redirect_url);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const params = new URLSearchParams(window.location.search);
  const role = params.get("role");
  const token = params.get("auth");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    await Axios.post(APP.API_MAIN_URL + "/auth/login", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        reset();
        localStorage.setItem(
          "userToken",
          JSON.stringify(res.data.authorisation.token)
        );
        setLoading(false);
        if (
          searchParams.get("redirect") &&
          searchParams.get("redirect").length > 1
        ) {
          let token = res.data.authorisation.token;
          let url;
          // APP.ADVERTISER_BASE_URL +
          //   `/auth/${token}?page=${searchParams.get("redirect")}`;
          // window.location.href = url;
          const user_role = res?.data?.user?.user_roles;
          if (user_role.length !== 0) {
            user_role.map((role, i) => {
              if (
                role.role_id === 1 ||
                role.role_id === 2 ||
                role.role_id === 3
              ) {
                url =
                  APP.ADMIN_BASE_URL +
                  "/auth/" +
                  encodeURIComponent(res.data.authorisation.token);
              } else if (role.role_id === 4) {
                url =
                  APP.ADVERTISER_BASE_URL +
                  `/auth/${token}?page=${searchParams.get("redirect")}`;
                window.location.href = url;
              }
            });
            window.location.href = url;
          }
        } else {
          if (res?.data?.user?.user_roles?.length !== 0) {
            let user_role = null;
            res?.data?.user?.user_roles?.map((role, index) => {
              if (role?.is_default === "1" || role?.is_default === true) {
                user_role = role?.role_id;
                if (user_role === 1 || user_role === 2 || user_role === 3) {
                  window.location.href =
                    APP.ADMIN_BASE_URL +
                    "/auth/" +
                    encodeURIComponent(res.data.authorisation.token);
                } else if (user_role === 4) {
                  const token = encodeURIComponent(
                    res.data.authorisation.token
                  );
                  const AllProfile = res.data.user.user_profiles;
                  const default_profile = AllProfile.map(
                    (item) => item.is_default === 1
                  );

                  let profileType = null;
                  let profileId = null;
                  let url;
                  // console.log("defoux", default_profile);
                  if (default_profile.length !== 0) {
                    profileType = default_profile.type;
                    profileId = default_profile.profile_id;
                    url =
                      APP.ADVERTISER_BASE_URL +
                      `/auth/${token}/${profileType}/${profileId}`;
                    window.location.href = url;
                  } else if (
                    default_profile.length === 0 ||
                    res.data.user.user_roles.length === 0
                  ) {
                    url =
                      APP.ADVERTISER_BASE_URL + `/auth/${token}?page=profile`;
                    window.location.href = url;
                  } else {
                    url = APP.ADVERTISER_BASE_URL + `/profile`;
                    // `/auth/${token}/${profileType}/${profileId}?page=profile`;
                    window.location.href = url;
                  }
                } else if (user_role === 5) {
                  window.location.href =
                    "https://publisher.konvey.rw/profile?auth=" +
                    encodeURIComponent(res.data.authorisation.token);
                } else {
                  localStorage.setItem(
                    "accessToken",
                    res.data.authorisation.token
                  );
                  navigate("/protection");
                }
              }
            });
          } else {
            localStorage.setItem("accessToken", res.data.authorisation.token);
            navigate("/protection");
            // console.log("Navigate to protection", res.data);
          }
        }
      })
      .catch((err) => {
        console.log(err, "error");
        notify(
          "error",
          err?.response?.data?.message || "invalid credentials, try again!"
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    if (redirectUrl) {
      window.location = redirectUrl;
      console.log(window.location);
    }
    // url params
    token && window.location.replace("/protection");

    role
      ? localStorage.setItem("role", role)
      : localStorage.setItem("user", null);
  }, [redirectUrl, userRole, handleSubmit, data]);

  useEffect(() => {
    setSocialAuthFetching(true);
    Axios.get(APP.API_MAIN_URL + "/utils/auth/providers")
      .then((res) => {
        setSocialAuthFetching(false);
        setSocialAuthProviders(res.data.data);
        console.log("Error in fetching auth providers", res);
      })
      .catch((err) => {
        setSocialAuthFetching(false);
        console.log(err);
        notify("error", "Fetching social authentication failed");
      });
  }, []);

  const onKeyEnter = async () => {
    setLoading(true);
    await Axios.post(APP.API_MAIN_URL + "/auth/login", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        console.log(res.data.user.user_roles, "user role");
        reset();
        //setLoader(true);
        localStorage.setItem(
          "userToken",
          JSON.stringify(res.data.authorisation.token)
        );
        setLoading(false);
        // console.log(res.data.user.user_roles, "user role")
        if (res.data.user.user_roles.length !== 0) {
          let user_role = null;
          res?.data?.user?.user_roles?.map((role, index) => {
            if (role?.is_default === "1" || role?.is_default === true) {
              user_role = role?.role_id;
              console.log(user_role, "user role");
              if (user_role === 1 || user_role === 2 || user_role === 3) {
                window.location.href =
                  APP.ADMIN_BASE_URL +
                  "/auth/" +
                  encodeURIComponent(res.data.authorisation.token);
              } else if (user_role === 4) {
                window.location.href =
                  APP.ADVERTISER_BASE_URL +
                  "/auth/" +
                  encodeURIComponent(res.data.authorisation.token);
              } else if (user_role === 5) {
                window.location.href =
                  "https://publisher.konvey.rw/profile?auth=" +
                  encodeURIComponent(res.data.authorisation.token);
              } else {
                navigate(
                  "/protection?auth=" +
                    encodeURIComponent(res.data.authorisation.token)
                );
              }
            }
          });
        } else {
          navigate(
            "/protection?auth=" +
              encodeURIComponent(res.data.authorisation.token)
          );
        }
      })
      .catch((err) => {
        console.log(err, "error");
        notify(
          "error",
          err?.response?.data?.message || "invalid credentials, try again!"
        );
        setLoading(false);
      });
  };

  return (
    <div className="flex items-center bg-white w-full h-full">
      <ToasterComponent />
      {loader ? (
        <Loader loader={loader} setLoader={setLoader} />
      ) : (
        <div
          id="m-auto"
          className="flex flex-col bg-white w-full md:w-[500px] min-h-3/4 rounded-lg p-4 pb-8 shadow-2xl m-auto"
        >
          <div className="flex flex-col w-4/5 max-w-[400px] mx-auto">
            <img
              src={KONVEYColorful}
              alt=""
              className="self-center"
              width={100}
              height={50}
            />
            <h2 className=" font-bold text-fontBlack  text-center text-lg">
              Signin to your account
            </h2>
            <h2 className=" text-fontBlack text-base text-center cursor-pointer">
              or
              <Link to="/register" className=" ml-1">
                <u>register</u>
              </Link>
            </h2>
            <form
              className="flex flex-col w-full max-h-2/4 mt-4"
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevents the default form submission
                  onKeyEnter();
                }
              }}
            >
              <Input
                labelText={"Email"}
                labelFor={"email"}
                name={"email"}
                type={"text"}
                isRequired={true}
                placeholder={"email address"}
                customClass="w-full mb-2"
                onChange={(e) => {
                  e.target.value.length > 0
                    ? errors.email && delete errors.email
                    : (errors.email = { message: "Email is required" });
                  setData({ ...data, email: e.target.value });
                }}
                register={register}
                errors={errors}
              />

              <StyledInput
                labelText={"Password"}
                labelFor={"password"}
                name={"password"}
                type={passwordShown ? "text" : "password"}
                isRequired={true}
                placeholder={"password"}
                customClass="w-full"
                onChange={(e) => {
                  e.target.value.length > 0
                    ? errors.password && delete errors.password
                    : (errors.password = { message: "Password is required" });
                  setData({ ...data, password: e.target.value });
                }}
                register={register}
                errors={errors}
                content={
                  <div className="cursor-pointer mt-[2px]">
                    {passwordShown ? (
                      <FaRegEye color="#767676" onClick={togglePassword} />
                    ) : (
                      <FiEyeOff color="#767676" onClick={togglePassword} />
                    )}
                  </div>
                }
              />

              <div className="flex justify-between items-center w-full mt-4">
                <div className="flex m-2">
                  <input
                    type="checkbox"
                    id="rememberMe"
                    name="rememberMe"
                    className="border border-black"
                    {...register("rememberMe", {})}
                  />
                  <label
                    htmlFor="rememberMe"
                    className="font-nunito font-normal ml-2 self-center text-xs md:text-sm"
                  >
                    Remember me
                  </label>
                </div>
                <Link
                  to="/forgot-password"
                  className="underline underline-offset-2 text-xs md:text-sm"
                >
                  {" "}
                  Forgot Password?
                </Link>
              </div>
              <Button
                content="Submit"
                type="submit"
                loading={loading}
                btnColor="primary"
                style="text-white w-full h-[40px] mt-4"
                // disabled={loading}
              />
            </form>
            <div className=" w-full flex justify-between items-center my-6">
              <div className="w-1/4 md:w-1/3 h-[1px] bg-light"></div>
              <h2 className=" text-xs md:text-sm px-4 text-fontBlack ">
                or Log in with
              </h2>
              <div className="w-1/4 md:w-1/3 h-[1px] bg-light"></div>
            </div>

            <div className="flex flex-row justify-between w-full">
              {socialAuthFetching ? (
                <>
                  <div className="w-[30px] h-[30px] animate-pulse bg-gray rounded-full "></div>
                  <div className="w-[30px] h-[30px] animate-pulse bg-gray rounded-full "></div>
                  <div className="w-[30px] h-[30px] animate-pulse bg-gray rounded-full "></div>
                </>
              ) : (
                <>
                  {socialAuthProviders.map((provider, i) => {
                    return provider.status === "active" ? (
                      <div
                        key={i}
                        className="w-[30px] h-[30px] rounded-full cursor-pointer"
                        onClick={() =>
                          (window.location.href = provider.login_url)
                        }
                      >
                        <img
                          src={provider.logo}
                          alt=""
                          className="object-contain"
                        />
                      </div>
                    ) : null;
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
