
import React from 'react'
import { BsTwitter } from 'react-icons/bs';
import { BsFacebook } from 'react-icons/bs';
import {AiFillInstagram} from 'react-icons/ai'


const Footer = () => {
  return (
    <div className='w-full flex flex-col h-full m-0'>
      <div className="flex flex-col bg-primary w-full h-[90%] text-white items-center justify-center space-y-2">
        <h2 className='font-bold'>Get in touch</h2>
        <div className='text-sm'>+25078888890</div>
        <div className='text-sm'>info@konvey.rw</div>
        <div className='flex flex-row space-x-2'>
           <BsFacebook size={15}/>
           <BsTwitter size={15}/>
           <AiFillInstagram size={15}/>
        </div>
      </div>
      <div className='h-[10%] bg-white text-primary flex items-center justify-center text-sm'>
        <p>&copy;copyrights, 2023 All rights reserved.</p>
      </div>
    </div>
  )
}

export default Footer
