import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import StyledInput from "../components/StyledInput";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useForm } from "react-hook-form";
import Button from "../components/Button";
import { BsFacebook } from "react-icons/bs";
import { FaTwitter } from "react-icons/fa";
import { AiFillGoogleCircle } from "react-icons/ai";
import Axios from "../components/Axios";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastComponent, ToasterComponent } from "../components/Toast";
import { Link } from "react-router-dom";
import KONVEYColorful from "../assets/KONVEYColorful.png";
import { set } from "date-fns";
import { APP } from "../utils/constant";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [verify, setVerify] = useState(false);
  const [code, setCode] = useState(0);
  const [inputError, setInputError] = useState({});
  const userRole = localStorage.getItem("role");
  const params = new URLSearchParams(window.location.search);
  const role = params.get("role");
  const token = params.get("auth");
  const [socialAuthFetching, setSocialAuthFetching] = useState(false);
  const [socialAuthProviders, setSocialAuthProviders] = useState([]);
  const [verifyLoading, setVerifyLoading] = useState(false);

  const clearForm = () => {
    document.getElementById("myForm").reset();
  };

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const confirmCode = (data) => {
    setLoading(true);
    setVerify(true);
    setVerifyLoading(true);
    Axios.post(APP.API_MAIN_URL + "/account/verify", {
      verify_token: code,
    })
      .then((res) => {
        setLoading(false);
        setVerifyLoading(false);
        console.log(res);
        notify("success", "Account verified successfully!");
        clearForm();
        setTimeout(() => {
          window.location.replace("/");
        }, 2000);
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        setLoading(false);
        notify(
          "error",
          err?.response?.data?.message || err?.message === "Network error"
            ? "Network error, please try again!"
            : "Invalid code"
        );
        clearForm();
        setVerifyLoading(false);
      });
  };

  const moveToNextInput = (e) => {
    const input = e.target;
    const maxLength = parseInt(input.getAttribute("maxlength"));
    const inputValue = input.value;

    if (inputValue.length === maxLength) {
      const next = input.nextElementSibling;
      const previous = input.previousElementSibling;

      if (next !== null) {
        next.focus();
      } else {
        // check if all inputs are filled, perform verification
        const inputs = document.querySelectorAll("input[type='text']");
        let verificationCode = "";
        for (let i = 0; i < inputs.length; i++) {
          verificationCode += inputs[i].value;
        }
        setCode(verificationCode);
        console.log(verificationCode, "code");
        setVerify(true);
        //confirmCode(verificationCode);
      }
    }
  };

  // redirect url
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    phone_code: "",
    phone: "",
    confirm_password: "",
    role: userRole === "advertiser" ? 4 : userRole === "publisher" ? 5 : null,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    console.log(inputError, "inputError");
    // if (Object.keys(inputError).length > 0) {
    //   notify('error', inputError.message);
    // }
  }, [inputError]);

  useEffect(() => {
    if (redirectUrl) {
      window.location = redirectUrl;
    }
    // url params
    token
      ? localStorage.setItem("auth", token)
      : localStorage.setItem("user", null);

    role
      ? localStorage.setItem("role", role)
      : localStorage.setItem("user", null);
  }, [redirectUrl, userRole, handleSubmit]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      console.log(data, "data to submit");
      await Axios.post(APP.API_MAIN_URL + "/auth/register", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          setLoading(false);
          if (res.status === "success") {
            notify("success", "Registration successful!");
          }
          if (res.errors) {
            setInputError(res.errors);
          }
          setVerify(true);
          console.log(res);
          reset();
        })
        .catch((error) => {
          setInputError(error?.response?.data?.errors);
          setLoading(false);
        });
    } catch (error) {
      setInputError(error?.response?.data?.errors);
    }
  };

  // function to register with google
  const handleGoogleSignUp = async () => {
    try {
      const response = await Axios.get(APP.API_MAIN_URL + "/auth/google")
        .then((res) => {
          if (res.status === 200) {
            setRedirectUrl(res.data.redirect_url);
            console.log(res);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // function to register with twitter
  const handleFacebookSignUp = async () => {
    try {
      const response = await Axios.get(APP.API_MAIN_URL + "/auth/facebook")
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setRedirectUrl(res.data.redirect_url);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // function to register with Twitter
  const handleTwitterSignUp = async () => {
    try {
      const response = await Axios.get(APP.API_MAIN_URL + "/auth/twitter")
        .then((res) => {
          if (res.status === 200) {
            setRedirectUrl(res.data.redirect_url);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSocialAuthFetching(true);
    Axios.get(APP.API_MAIN_URL + "/utils/auth/providers")
      .then((res) => {
        setSocialAuthFetching(false);
        setSocialAuthProviders(res.data.data);
        console.log(res);
      })
      .catch((err) => {
        setSocialAuthFetching(false);
        console.log(err);
        notify("error", "Fetching social authentication failed");
      });
  }, []);

  const extractCountryCode = (phone) => {
    // remove non digits
    const code = phone.replace(/\D/g, "");
    return code;
  };

  // const handlePhoneChange = (phone) => {
  //   const countryCode = extractCountryCode(phone);
  //   setData({ ...data, phone: `+${countryCode}${phone}` });
  // }

  return (
    <div className="flex flex-col justify-center items-center font-nunito bg-secondary h-full w-full rounded-lg ">
      <div className="flex flex-col  w-full md:w-[500px] min-h-3/4 rounded-lg p-4  shadow-2xl lg:m-auto">
        <div className={`${verify ? "hidden" : "flex"} flex-col`}>
          <div className="flex flex-col">
            <img
              src={KONVEYColorful}
              alt=""
              className="self-center"
              width={100}
              height={50}
            />
            <div className="flex flex-col">
              <p className="text-xl leading-[24px] text-footerColor font-bold font-nunito flex justify-center">
                Register your account
              </p>
              <div className="flex flex-row justify-center items-center w-full space-x-2">
                <p>or</p>
                <Link to="/" className=" ml-1">
                  {" "}
                  <u>login</u>
                </Link>
              </div>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col w-[300px] px-6  md:w-[448px] self-center"
          >
            <Input
              key="01"
              labelText={"First name"}
              labelFor={"first_name"}
              id="01"
              name={"first_name"}
              type={"text"}
              placeholder={"Enter your first name"}
              customClass="w-full mb-2"
              onChange={(e) => {
                setData({ ...data, first_name: e.target.value });
              }}
              register={register}
              errors={errors}
            />
            {inputError?.first_name && (
              <div className="text-red-500 text-xs">
                {inputError?.first_name.map((first_name) => {
                  return (
                    <div className="flex flex-row space-2">
                      <p>{first_name}</p>
                    </div>
                  );
                })}
              </div>
            )}
            <Input
              key="02"
              labelText={"Last name"}
              labelFor={"last_name"}
              id="02"
              name={"last_name"}
              type={"text"}
              placeholder={"Enter your last name"}
              customClass="w-full mb-2"
              onChange={(e) => {
                setData({ ...data, last_name: e.target.value });
              }}
              register={register}
              errors={errors}
            />
            {inputError?.last_name && (
              <div className="text-red-500 text-xs">
                {inputError?.last_name.map((last_name) => {
                  return (
                    <div className="flex flex-row space-2">
                      <p>{last_name}</p>
                    </div>
                  );
                })}
              </div>
            )}
            <Input
              key="03"
              labelText={"Email"}
              labelFor={"email"}
              id="03"
              name={"email"}
              type={"text"}
              placeholder={"Enter email address"}
              customClass="w-full mb-2"
              onChange={(e) => {
                setData({ ...data, email: e.target.value });
              }}
              register={register}
              errors={errors}
            />
            {inputError?.email && (
              <div className="text-red-500 text-xs">
                {inputError?.email.map((email) => {
                  return (
                    <div className="flex flex-row space-2">
                      <p>{email}</p>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="w-full mb-2">
              <h1 className="">Phone</h1>
              <PhoneInput
                country={"rw"}
                onlyCountries={["rw"]}
                countryCodeEditable={false}
                value={data.phone.number}
                disableCountryCode={false}
                onChange={(value, data, e, formattedValue) => {
                  setData((prevData) => {
                    console.log(value.slice(data.dialCode.length));
                    return {
                      ...prevData,
                      phone_code: data.dialCode,
                      phone: value.slice(data.dialCode.length),
                    };
                  });
                }}
                containerClass="w-full"
              />
            </div>
            {inputError?.phone && (
              <div className="text-red-500 text-xs">
                {inputError?.phone.map((phone) => {
                  return (
                    <div className="flex flex-row space-2">
                      <p>{phone}</p>
                    </div>
                  );
                })}
              </div>
            )}

            <Input
              key="04"
              labelText={"Password"}
              labelFor={"password"}
              id="04"
              name={"password"}
              type={"password"}
              placeholder={"Enter password"}
              customClass="w-full mb-2"
              onChange={(e) => {
                setData({ ...data, password: e.target.value });
              }}
              register={register}
              errors={errors}
            />
            {inputError?.password && (
              <div className="text-red-500 text-xs">
                {inputError?.password.map((password) => {
                  return (
                    <div className="flex flex-row space-2">
                      <p>{password}</p>
                    </div>
                  );
                })}
              </div>
            )}
            <Input
              key="05"
              labelText={"Confirm password"}
              labelFor={"confirmPassword"}
              id="05"
              name={"confirmPassword"}
              type={"password"}
              placeholder={"confirm password"}
              customClass="w-full mb-2"
              onChange={(e) => {
                setData({ ...data, confirm_password: e.target.value });
              }}
              register={register}
              errors={errors}
            />
            {inputError?.confirm_password && (
              <div className="text-red-500 text-xs">
                {inputError?.confirm_password.map((confirm_password) => {
                  return (
                    <div className="flex flex-row space-2">
                      <p>{confirm_password}</p>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="flex justify-start items-center mb-2">
              <input
                type="checkbox"
                id="rememberMe"
                name="rememberMe"
                className="border border-black"
              />
              <label
                htmlFor="rememberMe"
                className="font-nunito font-normal ml-2 self-center"
              >
                remember me
              </label>
            </div>
            <Button
              content="Create account"
              type="submit"
              loading={loading}
              btnColor="primary"
              style="text-white w-full  mr-4 ml-0"
              disabled={true}
            />
            <div className=" w-full flex justify-between items-center my-6">
              <div className="w-1/4 md:w-1/3 h-[1px] bg-light"></div>
              <h2 className=" text-xs md:text-sm px-4 text-fontBlack ">
                or register with
              </h2>
              <div className="w-1/4 md:w-1/3 h-[1px] bg-light"></div>
            </div>
            <div className="flex flex-row justify-between w-full items-center">
              {socialAuthFetching ? (
                <>
                  <div className="w-[30px] h-[30px] animate-pulse bg-gray rounded-full "></div>
                  <div className="w-[30px] h-[30px] animate-pulse bg-gray rounded-full "></div>
                  <div className="w-[30px] h-[30px] animate-pulse bg-gray rounded-full "></div>
                </>
              ) : (
                <>
                  {socialAuthProviders.map((provider) => {
                    return provider.status === "active" ? (
                      <div
                        className="w-[30px] h-[30px] rounded-full cursor-pointer"
                        onClick={() =>
                          (window.location.href = provider.register_url)
                        }
                      >
                        <img
                          src={provider.logo}
                          alt=""
                          className="object-contain"
                        />
                      </div>
                    ) : null;
                  })}
                </>
              )}
            </div>
          </form>
        </div>
        {/* verify account */}
        <div
          className={`flex-col space-y-4 font-nunito ${
            verify ? "flex" : "hidden"
          }`}
        >
          <img
            src={KONVEYColorful}
            alt=""
            className="self-center"
            width={100}
            height={50}
          />
          <div className="text-2xl  flex justify-center items-center">
            Hello&nbsp;<b>{data.first_name},</b>&nbsp; Welcome!{" "}
          </div>
          <div className="w-[95%] mx-auto py-4 bg-light rounded-sm bg-opacity-20 flex text-center justify-center">
            Verify <b>&nbsp;{data.email}</b>
          </div>
          <p className="w-full font-bold flex items-center justify-center text-lg">
            Why verify?
          </p>
          <div>
            Konvey is a platform that allows you to create and manage your,
            events, campaigns, and more. We need to verify your email address to
            ensure that you are the owner of this email address.
          </div>
          <div>
            <div className="flex flex-col items-center justify-center space-y-2">
              <h1>Please check your email</h1>
              <h2 className="text-primary opacity-50 text-[15px]">
                we have sent code to {data.email}{" "}
              </h2>
            </div>
            <form onSubmit={handleSubmit(confirmCode)} id="myForm">
              <div className="flex items-center justify-center my-2">
                <input
                  type="text"
                  className="w-10 h-12 mr-2 text-center border border-gray-300 rounded-md font-bold"
                  maxLength={1}
                  onInput={(event) => moveToNextInput(event)}
                  required
                />
                <input
                  type="text"
                  className="w-10 h-12 mr-2 text-center border border-gray-300 rounded-md font-bold"
                  maxLength={1}
                  onInput={(event) => moveToNextInput(event)}
                  required
                />
                <input
                  type="text"
                  className="w-10 h-12 mr-2 text-center border border-gray-300 rounded-md font-bold"
                  maxLength={1}
                  onInput={(event) => moveToNextInput(event)}
                  required
                />
                <input
                  type="text"
                  className="w-10 h-12 mr-2 text-center border border-gray-300 rounded-md font-bold"
                  maxLength={1}
                  onInput={(event) => moveToNextInput(event)}
                  required
                />
                <input
                  type="text"
                  className="w-10 h-12 mr-2 text-center border border-gray-300 rounded-md font-bold"
                  maxLength={1}
                  onInput={(event) => moveToNextInput(event)}
                  required
                />
                <input
                  type="text"
                  className="w-10 h-12 mr-2 text-center border border-gray-300 rounded-md font-bold"
                  maxLength={1}
                  onInput={(event) => moveToNextInput(event)}
                  required
                />
              </div>
              <div className="text-[12px] my-2 flex items-center justify-center">
                <div>
                  Didn't get a code?{" "}
                  <button className="font-bold cursor-pointer ml-2">
                    Click to resend
                  </button>
                </div>
              </div>
              <div className="flex space-x-4 items-end justify-end">
                <Button
                  content="Verify account"
                  type="submit"
                  btnColor="black"
                  style="text-white w-max  mr-4 my-4 ml-0"
                  loading={verifyLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToasterComponent />
    </div>
  );
};

export default Register;
