import React from 'react';
import { ScaleLoader } from 'react-spinners';

const ScaleLoaderComponent = (loader) => {
  return (
    <div className="loader-container flex item-center justify-center w-full my-auto">
      <ScaleLoader color="#2C2C2C" loading={loader} size={150} />
    </div>
  );
};

export default ScaleLoaderComponent;
