import React, { useState, useEffect } from "react";
import Button from "../components/Button";
import KONVEYColorful from "../assets/KONVEYColorful.png";
import ScaleLoaderComponent from "../components/ScaleLoader";
import { useNavigate } from "react-router-dom";
import { APP } from "../utils/constant";

const Redirect = () => {
  const [loading, setLoading] = useState(false);
  const [loginFail, setLoginFail] = useState(false);

  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);

  // Create a URLSearchParams object from the URL string
  const urlParams = new URLSearchParams(params);

  // Retrieve the values for 'login', 'token', and 'role' parameters
  const loginState = urlParams.get("login");
  const token = urlParams.get("token");
  const role = urlParams.get("role");
  const message = urlParams.get("message");

  useEffect(() => {
    if (loginState === "success") {
      setLoginFail(false);
      if (role === "4") {
        localStorage.clear();
        window.location.href =
          APP.ADVERTISER_BASE_URL + "/?auth=" + encodeURIComponent(token);
      } else if (role === "2" || role === "3") {
        localStorage.clear();
        window.location.href =
          APP.ADMIN_BASE_URL + "/?auth=" + encodeURIComponent(token);
      } else if (role === "null") {
        navigate("/protection?auth=" + encodeURIComponent(token));
      }
    } else if (loginState === "failed") {
      setLoginFail(true);
    }
  }, []);

  return (
    <div className="flex flex-col w-full h-full font-nunito bg-secondary mb-0">
      <div className="fixed top-[0px] h-[40px] z-[10] pl-4 pt-1 w-full bg-secondary">
        <img src={KONVEYColorful} alt="" className="self-start" width={100} />
      </div>
      {loginFail ? (
        <div className="h-fit w-fit m-auto px-4">
          <h1 className="text-6xl">Oops!</h1>
          <h1 className="text-center  mb-4">{`${message}`}</h1>

          <Button
            content="Go to homepage"
            type="submit"
            loading={false}
            btnColor="primary"
            style="flex self-center items-center justify-center text-white w-full"
            disabled={true}
            onClick={() => {
              window.location = "/";
            }}
          />
        </div>
      ) : (
        <div className="h-fit w-fit m-auto px-4">
          <h1 className="text-center  mb-4">
            Please wait while we redirect you to your account.
          </h1>
          <ScaleLoaderComponent loading={loading} />
        </div>
      )}
    </div>
  );
};

export default Redirect;
