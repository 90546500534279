import React, { useEffect, useState } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import Axios from "../components/Axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import KONVEYColorful from "../assets/KONVEYColorful.png";
import { ToastComponent, ToasterComponent } from "../components/Toast";
import { useForm } from "react-hook-form";
import { APP } from "../utils/constant";

const ForgotPassword = () => {
  const [resetMethod, setResetMethod] = useState("email");
  const [data, setData] = useState({
    country_code: "178",
    reset_type: resetMethod,
    phone: "",
  });
  const [loading, setLoading] = useState(false);
  const [inputError, setInputError] = useState(false);

  const handleMethod = (e) => {
    setResetMethod(e.target.value);
  };

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async () => {
    setLoading(true);
    await Axios.post(
      APP.API_MAIN_URL + "/account/forgot-password",
      resetMethod === "email"
        ? { email: data.email, reset_type: "email", country_code: "178" }
        : { phone: data.phone, country_code: "178", reset_type: "phone" },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        setLoading(false);
        notify("success", res.data.message);
        reset();
        setTimeout(() => {
          window.location.href = "/";
        }, 5000);
      })
      .catch((err) => {
        setLoading(false);
        notify("error", err.response.data.message);
      });
  };

  return (
    <div className="flex flex-col space-y-4 items-center justify-center h-screen">
      <div className="flex flex-col space-y-4 rounded-lg p-8 shadow-2xl md:w-1/3 w-full">
        <div className="bg-secondary">
          <img
            src={KONVEYColorful}
            alt=""
            className="self-center"
            width={100}
            height={50}
          />
        </div>
        <h1>Forgot Password</h1>
        <p>Select your reset method.</p>
        <div className="w-full">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-row space-x-8">
              <div className="flex space-x-2">
                <input
                  type="radio"
                  name="email"
                  id="email"
                  value="email"
                  onChange={(e) => handleMethod(e)}
                  checked={resetMethod === "email"}
                />
                <label htmlFor="email">Email</label>
              </div>
              <div className="flex space-x-2">
                <input
                  type="radio"
                  name="phone"
                  id="phone"
                  value="phone"
                  checked={resetMethod === "phone"}
                  onChange={(e) => handleMethod(e)}
                />
                <label htmlFor="phone">Phone</label>
              </div>
            </div>
            <div className="flex flex-col space-y-4">
              {resetMethod === "phone" && (
                <div>
                  <label htmlFor="phone">Phone</label>
                  <PhoneInput
                    country={"rw"}
                    required="true"
                    onlyCountries={["rw"]}
                    countryCodeEditable={false}
                    value={data.phone.number}
                    disableCountryCode={false}
                    onChange={(value, data, e, formattedValue) => {
                      setData((prevData) => {
                        console.log(value.slice(data.dialCode.length));
                        return {
                          ...prevData,
                          phone_code: data.dialCode,
                          phone: value.slice(data.dialCode.length),
                        };
                      });
                    }}
                    containerClass="w-full"
                  />
                </div>
              )}
              {resetMethod === "email" && (
                <Input
                  key="03"
                  labelText={"Email"}
                  labelFor={"email"}
                  id="03"
                  name={"email"}
                  type={"email"}
                  required={true}
                  placeholder={"Enter email address"}
                  customClass="w-full"
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                  }}
                  register={register}
                  errors={errors}
                />
              )}
              <Button
                content="Continue"
                btnColor="primary"
                type="submit"
                style="w-full text-white"
                loading={loading}
              />
            </div>
            <ToasterComponent />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
