import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import { useForm } from "react-hook-form";
import Axios from "../components/Axios";
import { ToastComponent, ToasterComponent } from "../components/Toast";
import { set } from "date-fns";
import { APP } from "../utils/constant";

const ResetPassword = () => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const [inputError, setInputError] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    password: "",
    confirm_password: "",
    reset_token: token,
  });

  useEffect(() => {
    console.log(inputError, "inputError");
  }, [inputError]);

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = () => {
    setLoading(true);
    Axios.post(APP.API_MAIN_URL + "/account/reset-password", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        console.log(res);
        setLoading(false);
        reset();
        notify("success", "Password changed successfully");
        setTimeout(() => {
          window.location.href = "/";
        }, 3000);
        if (res.errors) {
          setInputError(res.errors);
        }
      })
      .catch((error) => {
        console.log(error);
        setInputError(error.response.data.errors);
        //notify("error", "Something went wrong");
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col w-full m-0 h-full bg-secondary justify-center items-center">
      <ToasterComponent />
      <div className="flex flex-col w-3/4 lg:w-1/4 items-center justify-center bg-white p-8 shadow-2xl">
        <form
          action="#none"
          className="flex flex-col w-full max-h-2/4 mt-4"
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault(); // Prevents the default form submission
              handleSubmit(onSubmit)();
            }
          }}
        >
          <Input
            labelText={"New Password"}
            labelFor={"password"}
            name={"new_password"}
            type={"password"}
            isRequired={true}
            placeholder={"enter new password"}
            customClass="w-full mb-2"
            onChange={(e) => {
              setData({ ...data, password: e.target.value });
            }}
            register={register}
            errors={errors}
          />
          {inputError?.password && (
            <div className="text-red-500 text-xs">
              {inputError?.password.map((password) => {
                return (
                  <div className="flex flex-row space-2">
                    <p>{password}</p>
                  </div>
                );
              })}
            </div>
          )}

          <Input
            labelText={"Confirm Password"}
            labelFor={"confirmPassword"}
            name={"confirm_new_password"}
            type={"password"}
            isRequired={true}
            placeholder={"re-enter password"}
            customClass="w-full"
            onChange={(e) => {
              setData({ ...data, confirm_password: e.target.value });
            }}
            register={register}
            errors={errors}
          />
          {inputError?.confirm_password && (
            <div className="text-red-500 text-xs">
              {inputError?.confirm_password.map((confirm_password) => {
                return (
                  <div className="flex flex-row space-2">
                    <p>{confirm_password}</p>
                  </div>
                );
              })}
            </div>
          )}

          <Button
            content="Reset"
            type="submit"
            loading={loading}
            btnColor="primary"
            style="text-white w-full h-[40px] mt-4"
          />
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
