import React,{useState} from 'react'
import { DotLoader } from 'react-spinners';

const Loader = (loader,setLoader) => {
  return (
    <div className="loader-container flex item-center justify-center w-full my-auto">
        <DotLoader color="#000000" loading={loader} size={150} />
    </div>
  )
}

export default Loader