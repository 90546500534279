import React, { useState, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Register from './pages/Register';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ProtectedRoutes from './Routes/ProtectedRoutes';
import ProtectionPage from './pages/ProtectionPage';
import ResetPassword from './pages/ResetPassword';
import NotFound from './components/NotFound';
import Verify from './pages/Verify';
import Redirect from './pages/Redirect';
import { Test } from './Test';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<Login />} />
        <Route path="/protection" element={<ProtectionPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-account" element={<Verify />} />
        <Route path="/auth/callback/*" element={<Redirect />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
