// axiosMiddleware

import axios from 'axios';

const Axios = axios.create(); // No baseURL

// Add a request interceptor to customize headers for every request
Axios.interceptors.request.use(
  (config) => {
    // Add your custom headers here
    // config.headers.Accept = 'application/json';
    config.headers.Connection = 'keep-alive';

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default Axios;
