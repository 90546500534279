import React, { useEffect } from "react";
import { BsFillFilePersonFill } from "react-icons/bs";
import { GrFormNext } from "react-icons/gr";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { IoBusinessOutline } from "react-icons/io5";
import KONVEYColorful from "../assets/KONVEYColorful.png";
import Axios from "../components/Axios";
import { ToastComponent, ToasterComponent } from "../components/Toast";
import { APP } from "../utils/constant";

const ProtectionPage = () => {
  const token = localStorage.getItem("accessToken");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  return (
    <div className="flex flex-col w-full h-full font-nunito bg-secondary mb-0">
      <div className="flex flex-row w-full h-full">
        <div className="w-full md:w-1/2 ">
          <div className="fixed top-[0px] h-[40px] z-[10] pl-4 pt-1 w-full md:w-1/2 bg-secondary">
            {" "}
            <img src={KONVEYColorful} alt="" width={100} />
          </div>
          <div className="relative top-[40px] overflow-y-auto fixedHeight md:px-8 xlg:px-16">
            <div className="flex flex-col h-full w-full font-nunito bg-secondary pt-8 justify-center items-center">
              <div className="flex flex-col">
                <h1 className="font-bold text-2xl mb-8 text-center">
                  we want to identify you on Konvey ?
                </h1>
                <div
                  className="w-[90%] flex flex-row items-center py-4 px-2 shadow-md rounded-md bg-white justify-between mb-4 cursor-pointer hover:scale-110 transition ease-in self-center"
                  onClick={async () => {
                    await Axios.post(
                      APP.API_MAIN_URL + "/utils/user/user-roles",
                      {
                        role: 5,
                      },
                      config
                    )
                      .then((res) => {
                        console.log(res);
                        window.location.href =
                          "https://publisher.konvey.rw/profile?auth=" +
                          encodeURIComponent(token);
                      })
                      .catch((err) => {
                        console.log(err);
                        //window.location = '/protection?auth='+ encodeURIComponent(JSON.parse(token));
                        notify(
                          "error",
                          err.response.data.message || "application error"
                        );
                      });
                  }}
                >
                  <div className="flex items-center gap-4">
                    <BsFillFilePersonFill className="h-[50px] w-[50px]" />
                    <div className="flex flex-col">
                      <h1 className="font-bold text-lg">
                        Are you a publisher ?
                      </h1>
                    </div>
                  </div>

                  <GrFormNext className="h-[40px] w-[40px] lg:h-[50px] lg:w-[50px]" />
                </div>
                <div
                  className="w-[90%] flex flex-row items-center py-4 px-2 shadow-md rounded-md bg-white justify-between mb-4 cursor-pointer hover:scale-110 transition ease-in self-center"
                  onClick={async () => {
                    await Axios.post(
                      APP.API_MAIN_URL + "/utils/user/user-roles",
                      {
                        role: 4,
                      },
                      config
                    )
                      .then((res) => {
                        window.location.href =
                          APP.ADVERTISER_BASE_URL +
                          `/auth/${token}?page=profile`;
                      })
                      .catch((err) => {
                        console.log(err?.response?.data?.message);
                        //window.location = '/protection?auth='+ encodeURIComponent(JSON.parse(token));
                        notify(
                          "error",
                          err?.response?.data?.message || "application error"
                        );
                      });
                  }}
                >
                  <div className="flex items-center gap-4">
                    <HiOutlineOfficeBuilding className="h-[50px] w-[50px]" />
                    <div className="flex flex-col">
                      <h1 className="font-bold text-lg">
                        Are you an advertiser ?
                      </h1>
                    </div>
                  </div>

                  <GrFormNext className="h-[40px] w-[40px] lg:h-[50px] lg:w-[50px]" />
                </div>
                {/* <div className="w-[90%] flex flex-row items-center py-4 px-2 shadow-md rounded-md bg-white justify-between mb-4 cursor-pointer hover:scale-110 transition ease-in self-center">
                  <div className="flex items-center gap-4">
                    <IoBusinessOutline className="h-[50px] w-[50px]" />
                    <div className="flex flex-col">
                      <h1 className="font-bold text-lg">
                        Are you a looking for <br />
                        products, and win <br />
                        prizes watching ads?
                      </h1>
                      <h2 className="text-base"></h2>
                    </div>
                  </div>

                  <GrFormNext className="right-[0] h-[40px] w-[40px] lg:h-[50px] lg:w-[50px]" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 h-full hidden md:block pointer-events-none ">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpKAae1JUrTNIVMP9YQaoV_5VCvS_oWMaOAe2LTtkj9tl2WmxoQ6rdGXlBxr6dKGtRI98&usqp=CAU"
            alt="background"
            srcset=""
            className="w-full h-full object-cover"
          />
          <h1 className="absolute bottom-8 text-4xl font-bold text-white mx-8 bg-[#000] opacity-50 rounded-md p-2 ">
            “Konvey is simple, explore konvey , bring your campaigns and join
            the Konvey Community.”
          </h1>
        </div>
      </div>
      <ToasterComponent />
    </div>
  );
};

export default ProtectionPage;
